<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Domicilio Fiscal</h2>
         <BasicFormToolbar :actions="['new','save','list']" @new="openNew" @save="save" @list="list" >
         </BasicFormToolbar>
        <Fieldset :toggleable="true" legend="Datos Generales">
          <div class="p-fluid formgrid grid">
            <FormInputText wrapperClass="field col-3" label="Código postal" @change="selectCP" :valid=" validate.validations.postal_code" v-model="entity.postal_code" />
            <FormDropdownComplex :labelInOption="'c_Pais - Descripcion'" @change="updateStates()" :labelInValue="'c_Pais - Descripcion'" :wrapperClass="'field col-3'" label="País" v-model="entity.country_sat_code" :options="countries" optionLabel="c_Pais" optionValue="c_Pais" />
            <FormDropdownComplex :labelInOption="'c_Estado - Descripcion'" :disabled="disabled == 1" @change="updateMunicipalities()" :labelInValue="'Descripcion'" :wrapperClass="'field col-3'" label="Estado" v-model="entity.state_sat_code" :options="filterState" optionLabel="c_Estado" optionValue="c_Estado" />
            <FormDropdownComplex :labelInOption="'c_Municipio - Descripcion'" :disabled="disabled == 1" @change="updateLocalities()" :labelInValue="'Descripcion'" :wrapperClass="'field col-3'" label="Municipio" v-model="entity.municipality" :options="filterMunicipalities" optionLabel="c_Municipio" optionValue="c_Municipio" />
            <FormDropdownComplex :labelInOption="'c_Localidad - Descripcion'" :disabled="disabled == 1" @change="updateLocationName()" :labelInValue="'Descripcion'" :wrapperClass="'field col-3'" label="Localidad" v-model="entity.location" :options="filterLocalities" optionLabel="c_Localidad" optionValue="c_Localidad" />
            <FormDropdownComplex :labelInOption="'c_Colonia - Descripcion'" @change="updateSuburbName()" :labelInValue="'Descripcion'" :wrapperClass="'field col-3'" label="Colonia" v-model="entity.suburb" :options="filterColonies" optionLabel="c_Colonia" optionValue="c_Colonia" />
            <FormInputText wrapperClass="field col-3" label="Calle" :valid=" validate.validations.street" v-model="entity.street" />
            <FormInputText wrapperClass="field col-3" label="Número Exterior" :valid=" validate.validations.number_ext" v-model="entity.number_ext" />
            <FormInputText wrapperClass="field col-3" label="Número Interior" :valid=" validate.validations.number_int" v-model="entity.number_int" />
            <FormInputText wrapperClass="field col-3" label="Referencia" :valid=" validate.validations.reference" v-model="entity.reference" />
            <FormInputText type="mask" wrapperClass="field col-3" mask="*************" label="RFC" @change="genericRFC()" :valid=" validate.validations.rfc" v-model="entity.rfc" />
         </div>
        </Fieldset>
          <br>
          <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />  
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
      </div>
    </div>
  </div>
</template>

<script>
import { FiscalResidence } from  '../../../models/cfdi40/FiscalResidence';
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import { satCatalogo } from '../../../utilities/General';
export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities: [],
      states: [],
      countries: [],
      municipalities:[],
      localities:[],
      colonies:[],
      filterState:[],
      filterMunicipalities:[],
      filterLocalities:[],
      filterColonies:[],

      //* URL para subir el importador
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      disabled: 0,
      //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
      //* 'required' de tipo 'text'
      rules: [
        new Rule({ name: "street" }),
        new Rule({ name: "number_ext" }),
        // new Rule({ name: "number_int" }),
        new Rule({ name: "postal_code" }),
        // new Rule({ name: "reference" }),
        new Rule({ name: "state_sat_code" }),
        new Rule({ name: "country_sat_code"}),
        new Rule({ name: "location"}),     
        new Rule({ name: "municipality"}),                      
        new Rule({ name: "rfc" }),
      ],
      //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
      //* y el valid general en false (por que no esta validado aun)
      validate: {
        valid: false,
        validations: {
          street: null,
          number_ext: null,
          // number_int: null,
          postal_code: null,
          // reference: null,
          municipality: null,
          country: null,
          location: null,
          country_sat_code: null,
          rfc: null,
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [
        new HeaderGrid("Calle", "street"),
        new HeaderGrid("No. Ext", "number_ext"),
        new HeaderGrid("Código Postal", "postal_code"),
        new HeaderGrid("Colonia", "suburb_name"),
        new HeaderGrid("Municipio", "municipality_name"),
        new HeaderGrid("Localidad", "location_name"),
        new HeaderGrid("Referencia", "reference"),
        new HeaderGrid("Estado", "state_name"),
        new HeaderGrid("Pais", "country_sat_code"),
        new HeaderGrid("RFC", "rfc"),       
      ],        
      loading: false,
    };
  },
  components: { Loader , BasicFormToolbar, BasicDatatable, DeleteDialog, FormDropdownComplex, FormInputText},
  created() {
    console.log(this.session);
    this.entity = new FiscalResidence(this.session);
    this.uploadURL =
      this.$config.api_route +
      "cfdi40/FiscalResidence/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
    this.initFilters();
  },
  async mounted() {
    //Aqui cargamos los CATALOGOS ESTATICOS
    this.states = await satCatalogo(4);
    this.colonies = await satCatalogo(30);
    this.localities = await satCatalogo(7);    
    this.municipalities = await satCatalogo(10);
    this.countries = await satCatalogo(11);
    this.entity.country_sat_code = "MEX";      
    this.filterState = this.states.filter((x) => x.c_Pais === this.entity.country_sat_code);    

  },
  methods: {
    openNew() {
      this.entity = new FiscalResidence(this.session);
      this.entity.country_sat_code = "MEX";      
      this.filterState = this.states.filter((x) => x.c_Pais === this.entity.country_sat_code);
      this.filterMunicipalities = "";
      this.filterLocalities = "";
      this.filterColonies = "";   
      this.disabled=0;       
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },    
    async selectCP(){
       try {
                this.loading = true;
               let invoice = await this.entity.postalcode({
                    id: this.entity.postal_code,
                });

                if(invoice[0] != null)
                {
                  this.entity.state_sat_code = invoice[0].c_Estado;
                  this.entity.country_sat_code = "MEX";
                  this.filterState = this.states.filter((x) => x.c_Pais === this.entity.country_sat_code);
                  this.filterMunicipalities =  this.municipalities.filter((x) => x.c_Estado === this.entity.state_sat_code);
                  this.filterLocalities =  this.localities.filter((x) => x.c_Estado === this.entity.state_sat_code);
                  this.filterColonies =  this.colonies.filter((x) => x.c_CodigoPostal === this.entity.postal_code);                      
                  this.entity.municipality = invoice[0].c_Municipio;      
                  this.entity.location = invoice[0].c_Localidad; 
                  //Calcula el nombre del estado
                  this.entity.state_name = this.states.find(x => (x.c_Pais === "MEX" || x.c_Pais === "USA") && x.c_Estado === this.entity.state_sat_code)?.Descripcion;    
                  //Calcula el nombre del municipio 
                  this.entity.municipality_name = this.municipalities.find(x => x.c_Municipio === this.entity.municipality && x.c_Estado === this.entity.state_sat_code)?.Descripcion;
                  //Calcula el nombre de la localidad 
                  this.entity.location_name = this.localities.find(x => x.c_Estado === this.entity.state_sat_code && x.c_Localidad === this.entity.location)?.Descripcion;
                  this.disabled = 1;
                }
                else
                {
                  this.entity.municipality = "";
                  this.entity.state_sat_code = "";
                  this.entity.location = "";
                  this.entity.suburb = "";
                  this.entity.country_sat_code = "";
                  this.entity.municipality_name = "";
                  this.entity.location_name = "";
                  this.entity.suburb_name = "";
                  this.state_name = "";                     
                  this.disabled = 0;
                  throw "Código postal no encontrado en los catálogos del SAT.";
                }
      
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
    },
    updateStates() {
      let country = this.entity.country_sat_code
      if (country === "MEX" || country ==="USA"){
        this.filterState = this.states.filter((x) => x.c_Pais === country);
            }
      else{
        this.filterState = [];
        this.entity.municipality = "";
        this.entity.state_sat_code = "";
        this.entity.location = "";
        this.entity.suburb = "";
        this.entity.municipality_name = "";
        this.entity.location_name = "";
        this.entity.suburb_name = "";
        this.state_name = "";
        this.entity.rfc = "XEXX010101000"        
        // this.disabled = 1;        
      }
    },
    updateMunicipalities(){
      this.filterMunicipalities =  this.municipalities.filter((x) => x.c_Estado === this.entity.state_sat_code);
      //Calcula el nombre del estado
      this.entity.state_name = this.filterState.find(x => (x.c_Pais === "MEX" || x.c_Pais === "USA") && x.c_Estado === this.entity.state_sat_code)?.Descripcion;
    },   
    updateLocalities(){
      this.filterLocalities =  this.localities.filter((x) => x.c_Estado === this.entity.state_sat_code);
      //Calcula el nombre del municipio 
      this.entity.municipality_name = this.filterMunicipalities.find(x => x.c_Municipio === this.entity.municipality && x.c_Estado === this.entity.state_sat_code)?.Descripcion;
    },      
    updateLocationName(){
      //Calcula el nombre de la localidad 
      this.entity.location_name = this.filterLocalities.find(x => x.c_Estado === this.entity.state_sat_code && x.c_Localidad === this.entity.location)?.Descripcion;
    } ,   
    updateSuburbName(){
      //Calcula el nombre de la colonia 
      this.entity.suburb_name = this.filterColonies.find(x => x.c_CodigoPostal === this.entity.postal_code && x.c_Colonia === this.entity.suburb)?.Descripcion;
    },
    genericRFC(){
      if(this.entity.rfc === "XEXX010101000"){
        this.disabled = 0;
      }
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new FiscalResidence(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
      this.filterState = this.states.filter((x) => x.c_Pais === this.entity.country_sat_code);
      this.filterMunicipalities =  this.municipalities.filter((x) => x.c_Estado === this.entity.state_sat_code);
      this.filterLocalities =  this.localities.filter((x) => x.c_Estado === this.entity.state_sat_code);
      this.filterColonies =  this.colonies.filter((x) => x.c_CodigoPostal === this.entity.postal_code);

      //Se cargan los nombres estado, municipio, localidad y colonia
      this.entity.state_name = this.filterState.find(x => (x.c_Pais === "MEX" || x.c_Pais === "USA") && x.c_Estado === this.entity.state_sat_code)?.Descripcion;
      this.entity.municipality_name = this.filterMunicipalities.find(x => x.c_Municipio === this.entity.municipality && x.c_Estado === this.entity.state_sat_code)?.Descripcion;
      this.entity.location_name = this.filterLocalities.find(x => x.c_Estado === this.entity.state_sat_code && x.c_Localidad === this.entity.location)?.Descripcion;
      if (this.entity.suburb != null){
        this.entity.suburb_name = this.filterColonies.find(x => x.c_CodigoPostal === this.entity.postal_code && x.c_Colonia === this.entity.suburb)?.Descripcion;
      }
      else{
        this.entity.suburb_name="";
      }      
      this.disabled = 1;               
      this.scrollToTop();
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    deleted() {
        this.deleteEntity();
    },    
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new FiscalResidence(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
 
    async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await this.entity.all();
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
